.inventoryButtonClass{
    font-family: 'Noto Sans' !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    background-color: #195E97 !important;
    color: #FFFFFF !important;
    border-radius: 25px !important;
}

.deleteButtonClass{
    font-family: 'Noto Sans' !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    border-radius: 25px !important;
}

.inventoryItemGrid h6{
    font-family: 'Noto Sans' !important;
    font-weight: 600;
    font-size: 18px;
}

.inventoryItemGrid label{
    font-family: 'Noto Sans' !important;
    font-weight: 500;
    font-size: 12px;
}

.inventoryItemGrid p, .inventoryItemGrid input, .inventoryItemGrid textarea{
    font-family: 'Noto Sans' !important;
    font-weight: 500;
    font-size: 16px;
}

.inventoryItemGrid span{
    font-family: 'Noto Sans' !important;
    font-size: 14px;  
    font-weight: 500;  
}

.inventoryItemGrid{
    font-family: 'Noto Sans' !important;
    font-weight: 500;
}

@media only screen and (max-width: 600px) {
    .editableField{
        padding-top: 8px !important;
    }
}