.roundIcon{
    color: white !important;
    background: #195E97 !important;
    border: 5px solid #195E97 !important;
    border-radius: 30px !important;
    font-size: 2rem !important;
    margin-right: 3px !important;
}

.itemSummaryFormCard{
    padding: 4px;
}

.itemSummaryFormCard h6{
    font-family: 'Noto Sans' !important;
    font-weight: 600;
    font-size: 18px;
}

.itemSummaryFormCard .itemTextBody{
    font-family: 'Noto Sans' !important;
    font-weight: 500;
    font-size: 16px;
    color: #888888;
}

.itemSummaryFormCard .itemTag{
    color: #195E97;
}

.itemSummaryFormCard .itemTextCaption{
    color: #888888;
    font-size: 14px;
}

.MuiButtonBase-root{
    font-family: 'Noto Sans' !important;
    font-weight: 500;
}