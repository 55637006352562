html, body, #root {
    height: 100%;
}
#root {
    background-color: #eee;
}
.MuiPaper-root{
    border-radius: 0px !important;
    box-shadow: none !important;
}
.filterPaper{
    border-bottom: 1px solid #DADCE0 !important;
}