.cardContent{
    display: flex !important;
}

.formControl{
    flex-grow: 1 !important;
    flex-basis: 0 !important;
}

.MultiSelect{
    border-radius: 25px !important;
}


@media only screen and (max-width: 600px) {
    .formControl .MultiSelect{
        font-size: 14px !important;
        border-radius: 10px !important;
    }

    .formControl{        
        flex-basis: auto !important;
        justify-content: center !important;
    }

    .MuiSelect-outlined{
        padding-right: 0 !important;
    }

    .MuiSelect-outlined .MuiOutlinedInput-input{
        padding: 10px 8px !important;
    }
}